import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import styled from 'styled-components/macro'

import SwapheadBG from '../../assets/images/swaphead02.png'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import SettingsTab from '../Settings'

const StyledSwapHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  background: url(${SwapheadBG});
  background-size: 100% 100%;
  background-position: center;
  // box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  // border-top-left-radius: 5px;
  // border-top-right-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  height: 125px;
  color: ${({ theme }) => theme.text5};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 5px;
    height: 110px;
  `};
`

export default function SwapHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
  return (
    <StyledSwapHeader>
      <AutoColumn>
        <AutoColumn justify="end">
          <SettingsTab placeholderSlippage={allowedSlippage} />
        </AutoColumn>
        <AutoColumn justify="center">
          <TYPE.main fontWeight={900} fontSize={24}>
            <Trans>LV SWAP</Trans>
          </TYPE.main>
        </AutoColumn>
      </AutoColumn>
    </StyledSwapHeader>
  )
}
