import { Trans } from '@lingui/macro'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { CardBGImage, CardNoise, CardSection, DataCard } from 'components/earn/styled'
// import Footer from 'components/Footer'
import FormattedCurrencyAmount from 'components/FormattedCurrencyAmount'
import Loader from 'components/Loader'
import { AutoRow, RowBetween, RowCenter, RowFixed } from 'components/Row'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import DelegateModal from 'components/vote/DelegateModal'
import ProposalEmptyState from 'components/vote/ProposalEmptyState'
import { useActiveWeb3React } from 'hooks/web3'
import JSBI from 'jsbi'
import { darken } from 'polished'
import { Link } from 'react-router-dom'
import { Button } from 'rebass/styled-components'
import { useModalOpen, useToggleDelegateModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { ProposalData, useAllProposalData, useUserDelegatee, useUserVotes } from 'state/governance/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import styled from 'styled-components/macro'
import { ExternalLink, TYPE } from 'theme'
import { shortenAddress } from 'utils'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import CardNoise01 from '../../assets/images/cardNoiseDark.png'
import VoteBG01 from '../../assets/images/gist02.jpg'
import Gistdrystamp from '../../assets/images/gistdrystamp.png'
import CardBG01 from '../../assets/images/gistswap-card05.png'
import { ZERO_ADDRESS } from '../../constants/misc'
import { UNI } from '../../constants/tokens'
import { ProposalStatus } from './styled'

const PageWrapper = styled(AutoColumn)`
  justify-content: center;
  align-items: center;
`

const TopSection = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  // margin-top: 0.5rem;
`

const Proposal = styled(Button)`
  padding: 0.75rem 1rem;
  width: 100%;
  // margin-bottom: 0.2rem;
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  align-items: center;
  text-align: left;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  text-decoration: none;
  background: url(${CardNoise01});
  background-size: 100% 100%;
  background-position: center;
  border-radius: 3px;
  overflow: hidden;
  &:focus {
    background-color: ${({ theme }) => darken(0.05, theme.bg1)};
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: linear-gradient(
      to left,
      #000000,
      #161515,
      #252322,
      #33322f,
      #41423e,
      #474b42,
      #4a5547,
      #4c5f4d,
      #4d6242,
      #546435,
      #5e6426,
      #6c6314
    );
  }
`

const ProposalNumber = styled.span`
  color: ${({ theme }) => theme.text1};
`

const ProposalTitle = styled.span`
  font-weight: 400;
`

const VoteCard = styled(DataCard)`
  background: url(${CardBG01});
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  position: static;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
`

const BgStamp = styled(DataCard)`
  background: url(${Gistdrystamp});
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  // width: 500px;
  // height: 500px;
  // z-index: 2;
`

const WrapSmall = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
  `};
`

const TextButton = styled(TYPE.main)`
  color: ${({ theme }) => theme.primary1};
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const AddressButton = styled.div`
  border: 1px solid ${({ theme }) => theme.bg3};
  padding: 2px 4px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.text1};
`

const BgBlur02 = styled.div`
  height: 100%;
  max-width: 640px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.2rem 2rem;
  border-radius: 3px;
  background: ${({ theme }) => theme.bg6};
  // opacity: 0.9;
  position: static;
  z-index: 100;
`

const VoteBG = styled.div`
  height: 100%;
  width: 100%;
  background: url(${VoteBG01});
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  padding: 2% 20% 5% 22%;
  position: absolute;
  z-index: -99;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 15% 5% 5% 5%;
    background-size: cover;
  `};
`

// const FooterWrapper = styled.div`
//   position: absolute;
//   margin-top: 290%;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     width: 100%;
//     padding-left: 10%;
//     padding-bottom: 10%;
//     justify-content: center;
//     z-index: 999;
//   `};
// `

// const ScrollWrap = styled.div`
//   height: 100%;
//   width: 100%;
//   position: relative;
// `

export default function Vote() {
  const { account, chainId } = useActiveWeb3React()

  // toggle for showing delegation modal
  const showDelegateModal = useModalOpen(ApplicationModal.DELEGATE)
  const toggleDelegateModal = useToggleDelegateModal()

  // get data to list all proposals
  const { data: allProposals, loading: loadingProposals } = useAllProposalData()

  // user data
  const { loading: loadingAvailableVotes, votes: availableVotes } = useUserVotes()
  const uniBalance: CurrencyAmount<Token> | undefined = useTokenBalance(
    account ?? undefined,
    chainId ? UNI[chainId] : undefined
  )
  const userDelegatee: string | undefined = useUserDelegatee()

  // show delegation option if they have have a balance, but have not delegated
  const showUnlockVoting = Boolean(
    uniBalance && JSBI.notEqual(uniBalance.quotient, JSBI.BigInt(0)) && userDelegatee === ZERO_ADDRESS
  )

  return (
    <VoteBG>
      <PageWrapper gap="lg" justify="center">
        <DelegateModal
          isOpen={showDelegateModal}
          onDismiss={toggleDelegateModal}
          title={showUnlockVoting ? <Trans>Unlock Votes</Trans> : <Trans>Update Delegation</Trans>}
        />
        <TopSection gap="md">
          <VoteCard>
            <CardSection>
              <AutoColumn gap="lg" style={{ padding: '10px 10px 10px 35px' }}>
                <RowCenter>
                  <TYPE.white fontWeight={600}>
                    <Trans>LV DAO Governance</Trans>
                  </TYPE.white>
                </RowCenter>
                <RowBetween>
                  <TYPE.white fontSize={14}>
                    <Trans>
                      LV tokens represent voting shares in LV DAO Governance. You can vote on each proposal yourself or
                      delegate your votes to a third party.
                    </Trans>
                  </TYPE.white>
                </RowBetween>
                <ExternalLink
                  style={{ color: 'white', textDecoration: 'underline' }}
                  href="https://heungno.net/?p=9142"
                  target="_blank"
                >
                  <TYPE.white fontSize={14}>
                    <Trans>Read more about LV DAO Governance</Trans>
                  </TYPE.white>
                </ExternalLink>
              </AutoColumn>
            </CardSection>
            <CardBGImage />
            <CardNoise />
          </VoteCard>
        </TopSection>

        <BgBlur02>
          <TopSection gap="1rem">
            <RowCenter>
              <TYPE.darkGray fontWeight={1000} fontSize={'20px'} style={{ marginTop: '2rem', flexShrink: 0 }}>
                <Trans>PROPOSALS</Trans>
              </TYPE.darkGray>
            </RowCenter>
            <WrapSmall>
              <AutoRow gap="1rem" justify="center">
                {loadingProposals || loadingAvailableVotes ? <Loader /> : null}
                {showUnlockVoting ? (
                  <ButtonPrimary
                    style={{ width: 'fit-content' }}
                    padding="8px"
                    $borderRadius="3px"
                    onClick={toggleDelegateModal}
                  >
                    <Trans>Unlock Voting</Trans>
                  </ButtonPrimary>
                ) : availableVotes && JSBI.notEqual(JSBI.BigInt(0), availableVotes?.quotient) ? (
                  <TYPE.label fontWeight={500} mr="6px">
                    <Trans>
                      <FormattedCurrencyAmount currencyAmount={availableVotes} /> Votes
                    </Trans>
                  </TYPE.label>
                ) : uniBalance &&
                  userDelegatee &&
                  userDelegatee !== ZERO_ADDRESS &&
                  JSBI.notEqual(JSBI.BigInt(0), uniBalance?.quotient) ? (
                  <TYPE.label fontWeight={500} mr="6px">
                    <Trans>
                      <FormattedCurrencyAmount currencyAmount={uniBalance} /> Votes
                    </Trans>
                  </TYPE.label>
                ) : (
                  ''
                )}
                <ButtonPrimary
                  as={Link}
                  to="/create-proposal"
                  style={{ width: 'fit-content', borderRadius: '3px' }}
                  padding="8px"
                >
                  <Trans>Create Proposal</Trans>
                </ButtonPrimary>
              </AutoRow>
            </WrapSmall>
            {!showUnlockVoting && (
              <RowBetween style={{ margin: '2rem 0 1rem 0' }}>
                <div />
                {userDelegatee && userDelegatee !== ZERO_ADDRESS ? (
                  <RowFixed>
                    <TYPE.label fontWeight={500} mr="4px">
                      <Trans>Delegated to:</Trans>
                    </TYPE.label>
                    <AddressButton style={{ border: 'none' }}>
                      <StyledExternalLink
                        href={getExplorerLink(1, userDelegatee, ExplorerDataType.ADDRESS)}
                        style={{ margin: '0 4px', border: '0px solid', color: 'black' }}
                      >
                        {userDelegatee === account ? <Trans>Self</Trans> : shortenAddress(userDelegatee)}
                      </StyledExternalLink>
                      <TextButton onClick={toggleDelegateModal} style={{ marginLeft: '4px' }}>
                        <Trans>(edit)</Trans>
                      </TextButton>
                    </AddressButton>
                  </RowFixed>
                ) : (
                  ''
                )}
              </RowBetween>
            )}
            {allProposals?.length === 0 && <ProposalEmptyState />}
            {allProposals
              ?.slice(0)
              ?.reverse()
              ?.map((p: ProposalData) => {
                return (
                  <Proposal as={Link} to={`/vote/${p.governorIndex}/${p.id}`} key={`${p.governorIndex}${p.id}`}>
                    <ProposalNumber>
                      {p.governorIndex}.{p.id}
                    </ProposalNumber>
                    <ProposalTitle>{p.title}</ProposalTitle>
                    <ProposalStatus status={p.status} />
                  </Proposal>
                )
              })}
          </TopSection>
          <TYPE.subHeader color="text4" marginTop={'1rem'}>
            <Trans>A minimum threshold of 0.25% of the total GIS supply is required to submit proposals</Trans>
          </TYPE.subHeader>
        </BgBlur02>
        {/* <FooterWrapper>
          <Footer />
        </FooterWrapper> */}
      </PageWrapper>
      <SwitchLocaleLink />
    </VoteBG>
  )
}
