import React, { useMemo } from 'react'
import { Text, TextProps as TextPropsOriginal } from 'rebass'
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components/macro'

import { useIsDarkMode } from '../state/user/hooks'
import { Colors } from './styled'

export * from './components'

type TextProps = Omit<TextPropsOriginal, 'css'>

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  // upToMedium: 960,
  upToMedium: 1180,
  upToLarge: 1280,
}

// Migrating to a standard z-index system https://getbootstrap.com/docs/5.0/layout/z-index/
// Please avoid using deprecated numbers
export enum Z_INDEX {
  deprecated_zero = 0,
  deprecated_content = 1,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

const headBG1 =
  'linear-gradient(to top, #525955, #454844, #373834, #292826, #1b1a19, #1b1a19, #1b1a19, #1b1a19, #292826, #373834, #454844, #525955)'

const headBG2 =
  'linear-gradient(to top, #525955, #454844, #373834, #292826, #1b1a19, #201a16, #251915, #2a1717, #43241b, #59351a, #674a16, #6c6314)'

function colors(darkMode: boolean): Colors {
  return {
    darkMode,
    // base
    white,
    black,

    headBG1,
    headBG2,

    // text
    text1: darkMode ? '#f2f3f2' : '#f2f3f2',
    text2: darkMode ? '#d7dad9' : '#d7dad9',
    text3: darkMode ? '#afb6b2' : '#afb6b2',
    text4: darkMode ? '#525955' : '#525955',
    text5: darkMode ? '#181b19' : '#181b19',

    // backgrounds / greys
    bg0: darkMode ? '#525955' : '#525955',
    bg1: darkMode ? '#626a65' : '#626a65',
    bg2: darkMode ? '#88918c' : '#88918c',
    bg3: darkMode ? '#afb6b2' : '#afb6b2',
    bg4: darkMode ? '#e4e7e5' : '#e4e7e5',
    bg5: darkMode ? '#e4e7e5' : '#e4e7e5',
    bg6: darkMode ? '#f2f3f2' : '#f2f3f2',

    //specialty colors
    modalBG: darkMode ? 'rgb(13, 13, 13)' : 'rgb(13, 13, 13)',
    advancedBG: darkMode ? 'rgb(13, 13, 13)' : 'rgb(82, 89,85)',

    //primary colors
    primary1: darkMode ? '#eb0d00' : '#eb0d00',
    primary2: darkMode ? '#d91c12' : '#d91c12',
    primary3: darkMode ? '#ff9e99' : '#ff9e99',
    primary4: darkMode ? '#ffe7e6' : '#ffe7e6',
    primary5: darkMode ? '#ffcfcc' : '#ffcfcc',

    // color text
    primaryText1: darkMode ? '#eb0d00' : '#eb0d00',

    // secondary colors
    secondary1: darkMode ? '#ff3d33' : '#ff3d33',
    secondary2: darkMode ? '#525955' : '#525955',
    secondary3: darkMode ? '#525955' : '#525955',

    // other
    red1: darkMode ? '#d91c12' : '#d91c12',
    red2: darkMode ? '#c72b23' : '#c72b23',
    red3: '#eb0d00',
    green1: darkMode ? '#007D35' : '#007D35',
    yellow1: '#d7c628',
    yellow2: '#8c801a',
    yellow3: '#e7dd7e',
    blue1: darkMode ? '#0068FC' : '#0068FC',
    blue2: darkMode ? '#0068FC' : '#0068FC',
    error: darkMode ? '#DF1F38' : '#DF1F38',
    success: darkMode ? '#007D35' : '#007D35',
    warning: '#FF8F00',

    // dont wanna forget these blue yet
    blue4: darkMode ? '#33594D' : '#33594D',
    // blue5: darkMode ? '#EBF4FF' : '#EBF4FF',
  }
}

function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#888D9B' : '#888D9B',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text5'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'black'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} color={'text1'} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} color={'text4'} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow3'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text5'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={300} color={error ? 'red1' : 'text1'} {...props} />
  },
}

export const ThemedGlobalStyle = createGlobalStyle`
htmml {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg5} !important;
}
`
