import styled from 'styled-components/macro'

export const ToggleWrapper = styled.button<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width ?? '100%'};
  padding: 2px;
  background: ${({ theme }) => theme.bg0};
  border-radius: 5px;
  border: ${({ theme }) => '1px solid ' + theme.bg6};
  cursor: pointer;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`

export const ToggleElement = styled.span<{ isActive?: boolean; fontSize?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 0.8rem;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  justify-content: center;
  height: 100%;
  background: ${({ theme, isActive }) => (isActive ? theme.primary1 : 'none')};
  color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.text3)};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  font-weight: 500;
  white-space: nowrap;
  :hover {
    user-select: initial;
    color: ${({ theme, isActive }) => (isActive ? theme.text2 : theme.text3)};
  }
`
