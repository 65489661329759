import React from 'react'
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa'
import styled from 'styled-components/macro'

import { ExternalLink } from '../../theme'

function Footer() {
  const FooterFrame = styled.div`
    // background-color: #000000;
    display: flex;
    justify-content: space-between;
    position: static;
    margin-bottom: 1%;
    z-index: 0;
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      // position: absolute;
      justify-content: space-between;
      width: 100%;
      margin-top: 200%;
      padding: 5%;
      position: static;
      z-index: 999;
    `};
  `

  const FooterText = styled.div`
    font-size: 12px;
    font-weight: 500;
    margin: 10px 0px 5px 30px;
    justify-content: start;
    color: ${({ theme }) => theme.text1};
    ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: start;
    margin: 0;
    `};
  `

  const SocialIconsWrapper = styled.div`
    justify-content: end;
    margin: 5px 35px 5px 0px;
    display: flex;
    flex-direction: row;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: end;
    margin: 0 0 0 6rem;
    `};
  `

  const SNSBg = styled.div`
    width: 29px;
    hieght: 15px;
    background: radial-gradient(circle, rgba(82, 89, 85, 1) 48%, rgba(242, 243, 242, 1) 48%);
    margin-left: 10px;
    border-radius: 3px;
    align-items: center;
    padding: 3px 0px 0px 2px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 3px 0px 0px 1px;
    width: 28px;
    `};
    :hover {
      transform: scale(1.1);
      background: radial-gradient(circle, rgba(235, 13, 0, 1) 48%, rgba(242, 243, 242, 1) 48%);
      box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    }
  `

  const SocialIcons = styled.div`
    color: ${({ theme }) => theme.bg6};
    padding: 0px;
  `

  return (
    <FooterFrame>
      <FooterText>©2022 LVSWAP</FooterText>
      <SocialIconsWrapper>
        <SNSBg>
          <ExternalLink
            style={{ color: 'white', textDecoration: 'underline' }}
            target="_blank"
            href="https://twitter.com/fighterkerry?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Eprofile%3Afighterkerry%7Ctwgr%5EeyJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2hvcml6b25fdHdlZXRfZW1iZWRfOTU1NSI6eyJidWNrZXQiOiJodGUiLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NwYWNlX2NhcmQiOnsiYnVja2V0Ijoib2ZmIiwidmVyc2lvbiI6bnVsbH19&ref_url=https%3A%2F%2Finfonet.gist.ac.kr%2F"
          >
            <SocialIcons>
              <FaTwitterSquare size="25px" />
            </SocialIcons>
          </ExternalLink>
        </SNSBg>
        <SNSBg>
          <ExternalLink
            style={{ color: 'white', textDecoration: 'underline' }}
            target="_blank"
            href="https://www.facebook.com/Gistswap-110453904854267"
          >
            <SocialIcons>
              <FaFacebookSquare size="25px" />
            </SocialIcons>
          </ExternalLink>
        </SNSBg>
        <SNSBg>
          <ExternalLink
            style={{ color: 'white', textDecoration: 'underline' }}
            target="_blank"
            href="https://kr.linkedin.com/in/heung-no-lee-4808b38"
          >
            <SocialIcons>
              <FaLinkedin size="25px" />
            </SocialIcons>
          </ExternalLink>
        </SNSBg>
        <SNSBg>
          <ExternalLink
            style={{ color: 'white', textDecoration: 'underline' }}
            target="_blank"
            href="https://www.youtube.com/channel/UCWtKh2U303FrG3aNUNsqO-w"
          >
            <SocialIcons>
              <FaYoutubeSquare size="25px" />
            </SocialIcons>
          </ExternalLink>
        </SNSBg>
      </SocialIconsWrapper>
    </FooterFrame>
  )
}

export default Footer
