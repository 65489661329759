import { Trans } from '@lingui/macro'
// import Footer from 'components/Footer'
import { L2_CHAIN_IDS } from 'constants/chains'
import JSBI from 'jsbi'
import { Pair } from 'lvswap_goerli'
import { useContext, useMemo } from 'react'
import { ChevronsRight } from 'react-feather'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'

import PoolBG01 from '../../assets/images/gist01.jpg'
import CardBG01 from '../../assets/images/gistswap-card01.png'
import { ButtonOutlined, ButtonPrimary, ButtonSecondary } from '../../components/Button'
import Card from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import { CardBGImage, CardNoise, CardSection, DataCard } from '../../components/earn/styled'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import FullPositionCard from '../../components/PositionCard'
import { RowBetween, RowCenter, RowFixed } from '../../components/Row'
import { Dots } from '../../components/swap/styleds'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { BIG_INT_ZERO } from '../../constants/misc'
import { useV2Pairs } from '../../hooks/useV2Pairs'
import { useActiveWeb3React } from '../../hooks/web3'
import { useStakingInfo } from '../../state/stake/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { ExternalLink, TYPE } from '../../theme'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 12%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 15%;
  `};
`

const VoteCard = styled(DataCard)`
  background: url(${CardBG01});
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  z-index: 999;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
    text-align: center;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  border-radius: 5px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Layer2Prompt = styled(EmptyProposals)`
  margin-top: 25px;
`

const BgBlur = styled.div`
  height: 100%;
  margin-top: 2rem;
  border-radius: 5px;
  padding: 2rem;
  background: ${({ theme }) => theme.bg6};
  opacity: 1;
  z-index: 90;
`

const PoolBG = styled.div`
  height: 100%;
  width: 100%;
  background: url(${PoolBG01});
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  padding: 2% 20% 0 33%;
  position: absolute;
  z-index: -99;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 5% 5% 5% 20%;
    background-size: cover;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 5%;
    background-size: cover;
  `};
`

export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
    JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
  )
  const stakingPairs = useV2Pairs(stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter((stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  const ON_L2 = chainId && L2_CHAIN_IDS.includes(chainId)

  return (
    <PoolBG>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <VoteCard>
          <CardSection>
            <AutoColumn gap="lg" justify="center" zindex="3" style={{ padding: '10px 20px 10px 45px' }}>
              <RowCenter>
                <TYPE.white fontWeight={600}>
                  <Trans>Liquidity Provider Rewards</Trans>
                </TYPE.white>
              </RowCenter>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  <Trans>
                    Liquidity providers earn a 0.3% fee on all trades proportional to their share of the pool. Fees are
                    added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
                  </Trans>
                </TYPE.white>
              </RowBetween>
              <ExternalLink
                style={{ color: 'white', textDecoration: 'underline' }}
                target="_blank"
                href="https://heungno.net/?p=9142"
              >
                <TYPE.white fontSize={14}>
                  <Trans>Read more about providing liquidity</Trans>
                </TYPE.white>
              </ExternalLink>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </VoteCard>

        <BgBlur>
          {ON_L2 ? (
            <AutoColumn gap="lg" justify="center">
              <AutoColumn gap="md" style={{ width: '100%' }}>
                <Layer2Prompt>
                  <TYPE.body color={theme.text3} textAlign="center">
                    <Trans>V2 is not available on Layer 2. Switch to Layer 1 Ethereum.</Trans>
                  </TYPE.body>
                </Layer2Prompt>
              </AutoColumn>
            </AutoColumn>
          ) : (
            <AutoColumn gap="lg" justify="center">
              <AutoColumn gap="md" style={{ width: '100%' }}>
                <RowCenter>
                  <TYPE.darkGray
                    fontWeight={1000}
                    fontSize={'20px'}
                    style={{ marginTop: '10px', justifySelf: 'flex-start' }}
                  >
                    <Trans>LV liquidity</Trans>
                  </TYPE.darkGray>
                </RowCenter>
                <TitleRow style={{ marginTop: '1rem', marginBottom: '1rem', justifyContent: 'center' }} padding={'0'}>
                  <ButtonRow>
                    <ResponsiveButtonPrimary as={Link} padding="6px 8px" to="/add/v2/ETH">
                      <Text fontWeight={500} fontSize={16}>
                        <Trans>Create a pair</Trans>
                      </Text>
                    </ResponsiveButtonPrimary>
                    <ResponsiveButtonPrimary id="find-pool-button" as={Link} to="/pool/v2/find" padding="6px 8px">
                      <Text fontWeight={500} fontSize={16}>
                        <Trans>Import Pool</Trans>
                      </Text>
                    </ResponsiveButtonPrimary>
                    <ResponsiveButtonPrimary id="join-pool-button" as={Link} to="/add/v2/ETH" padding="6px 8px">
                      <Text fontWeight={500} fontSize={16}>
                        <Trans>Add V2 Liquidity</Trans>
                      </Text>
                    </ResponsiveButtonPrimary>
                  </ButtonRow>
                </TitleRow>

                {!account ? (
                  <Card padding={'80px'} border={'3px'}>
                    <TYPE.body color={theme.text4} textAlign="center">
                      <Trans>Connect to a wallet to view your liquidity.</Trans>
                    </TYPE.body>
                  </Card>
                ) : v2IsLoading ? (
                  <EmptyProposals>
                    <TYPE.body color={theme.text3} textAlign="center">
                      <Dots>
                        <Trans>Loading</Trans>
                      </Dots>
                    </TYPE.body>
                  </EmptyProposals>
                ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
                  <>
                    <ButtonSecondary>
                      <RowBetween>
                        <Trans>
                          {/* <ExternalLink href={'https://v2.info.uniswap.org/account/' + account}> */}
                          <ExternalLink href={'https://heungno.net/?p=9142'}>
                            Account analytics and accrued fees
                          </ExternalLink>
                          <span> ↗ </span>
                        </Trans>
                      </RowBetween>
                    </ButtonSecondary>
                    {v2PairsWithoutStakedAmount.map((v2Pair) => (
                      <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                    ))}
                    {stakingPairs.map(
                      (stakingPair, i) =>
                        stakingPair[1] && ( // skip pairs that arent loaded
                          <FullPositionCard
                            key={stakingInfosWithBalance[i].stakingRewardAddress}
                            pair={stakingPair[1]}
                            stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                          />
                        )
                    )}
                    <RowFixed justify="center" style={{ width: '100%' }}>
                      <ButtonOutlined
                        as={Link}
                        to="/migrate/v2"
                        id="import-pool-link"
                        style={{
                          padding: '8px 16px',
                          margin: '2rem',
                          borderRadius: '5px',
                          width: 'fit-content',
                          fontSize: '14px',
                        }}
                      >
                        <ChevronsRight size={16} style={{ marginRight: '8px' }} />
                        <Trans>Migrate Liquidity to V3</Trans>
                      </ButtonOutlined>
                    </RowFixed>
                  </>
                ) : (
                  <EmptyProposals>
                    <TYPE.body color={theme.text4} textAlign="center">
                      <Trans>No liquidity found.</Trans>
                    </TYPE.body>
                  </EmptyProposals>
                )}
              </AutoColumn>
            </AutoColumn>
          )}
        </BgBlur>
      </PageWrapper>
      <SwitchLocaleLink />
    </PoolBG>
  )
}
