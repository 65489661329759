import { Trans } from '@lingui/macro'
import useScrollPosition from '@react-hook/window-scroll'
import { CHAIN_INFO, SupportedChainId } from 'constants/chains'
import useTheme from 'hooks/useTheme'
import { darken } from 'polished'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Text } from 'rebass'
import { useShowClaimPopup, useToggleSelfClaimModal } from 'state/application/hooks'
import { useUserHasAvailableClaim } from 'state/claim/hooks'
import { useUserHasSubmittedClaim } from 'state/transactions/hooks'
import { useDarkModeManager } from 'state/user/hooks'
import { useETHBalances } from 'state/wallet/hooks'
import styled from 'styled-components/macro'

import HeaderBG01 from '../../assets/images/headerBG01.png'
import HeaderBG02 from '../../assets/images/headerBG02.png'
// import HeaderBGNav01 from '../../assets/images/headerBGNav01.png'
import ActiveBG03 from '../../assets/images/labelbg.png'
import { ReactComponent as Logo } from '../../assets/svg/logo.svg'
import { useActiveWeb3React } from '../../hooks/web3'
import { ExternalLink, TYPE } from '../../theme'
import ClaimModal from '../claim/ClaimModal'
import { CardNoise } from '../earn/styled'
import Menu from '../Menu'
import Modal from '../Modal'
import Row from '../Row'
import { Dots } from '../swap/styleds'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'
import UniBalanceContent from './UniBalanceContent'

// const HeaderFrame = styled.div<{ showBackground: boolean }>`
//   display: grid;
//   grid-template-columns: 120px 1fr 120px;
//   align-items: center;
//   justify-content: space-between;
//   align-items: center;
//   flex-direction: columns;
//   width: 100%;
//   top: 0;
//   position: relative;
//   padding: 1rem;
//   z-index: 21;
//   position: relative;
//   /* Background slide effect on scroll. */
//   background-image: ${({ theme }) => `linear-gradient(to bottom, transparent 50%, ${theme.bg6} 50% )}}`};
//   background-position: ${({ showBackground }) => (showBackground ? '0 -100%' : '0 0')};
//   background-size: 100% 200%;
//   box-shadow: 0px 0px 0px 1px ${({ theme, showBackground }) => (showBackground ? theme.bg2 : 'transparent;')};
//   transition: background-position 0.1s, box-shadow 0.1s;
//   background-blend-mode: hard-light;

//   ${({ theme }) => theme.mediaWidth.upToLarge`
//     grid-template-columns: 48px 1fr 1fr;
//   `};

//   ${({ theme }) => theme.mediaWidth.upToMedium`
//     padding:  1rem;
//     grid-template-columns: 1fr 1fr;
//   `};

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     padding:  1rem;
//     grid-template-columns: 36px 1fr;
//   `};
// `

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: columns;
  width: 100%;
  height: 77px;
  top: 0;
  position: relative;
  padding: 12px;
  z-index: 21;
  position: relative;
  /* Background slide effect on scroll. */
  // background-color: ${({ theme }) => theme.bg6};
  background: url(${HeaderBG01});
  background-size: 100% 100%;
  background-position: center;
  // overflow: hidden;
  // background-position: ${({ theme }) => theme.text4}};
  // background-size: 100% 200%;
  // transition: background-position 0.1s, box-shadow 0.1s;
  // background-blend-mode: hard-light;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  z-idex: -1;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    background: url(${HeaderBG02});
    padding: 1rem 0.5rem 0.5rem 0.1rem;
    grid-template-columns: 36px 1fr;
    height: 146px;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  margin: 1rem;
  z-idex: 999;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: end;
    padding-bottom: 80px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: end;
    padding-bottom: 80px;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`

const HeaderLinks = styled(Row)`
  justify-self: center;
  width: fit-content;
  height: 70px;
  // padding: 1rem;
  // border-radius: 0px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  overflow: auto;
  align-items: center;
  // background-color: #ebebeb;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: space-between;
    width: 300px;
    justify-self: center;
    flex-direction: row;
    grid-gap: 20px;
    height: auto;
    z-index: 99;
    position: fixed;
    top: 117px; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg0)};
  background: url(${ActiveBG03});
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  border-radius: 2px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 0px solid blue;
  }
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 100px;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const BalanceText = styled(Text)`
  color: ${({ theme }) => theme.text1};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
    margin-right: 25px;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  position: fixed;
  top: 20px;
  margin-right: 10px;
  :hover {
    transform: scale(1.1);
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 33px;
  `};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  height: 45px;
  width: 60px;
  justify-content: center;
  color: ${({ theme }) => theme.text5};
  font-size: 1rem;
  font-weight: 400;
  // padding: 10px 12px;
  margin-top: 5px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 45px;
    margin-bottom: 11.3px;
    padding-top: 6px
  `};
  &.${activeClassName} {
    font-weight: 600;
    justify-content: center;
    color: ${({ theme }) => theme.primary1};
    border-bottom: 3px solid ${({ theme }) => theme.primary1};
    // background-size: 100% 100%;
    // background-position: center;
    // overflow: hidden;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    border-bottom: 4px solid ${({ theme }) => theme.primary1};
  `};
  }
  :hover {
    color: ${({ theme }) => theme.text4};
  }
  :focus {
    color: ${({ theme }) => theme.primary1};
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text5};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  margin-top: 5px;
  font-weight: 400;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0px;
  `};

  &.${activeClassName} {
    border-radius: 5px;
    font-weight: 400;
    color: ${({ theme }) => theme.text5};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.05, theme.primary1)};
    text-decoration: none;
  }
`

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [darkMode] = useDarkModeManager()
  const { white, black } = useTheme()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()

  const scrollY = useScrollPosition()

  const { infoLink } = CHAIN_INFO[chainId ? chainId : SupportedChainId.MAINNET]
  return (
    // <HeaderFrame showBackground={scrollY > 45}>
    <HeaderFrame>
      <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <Title href=".">
        <UniIcon>
          <Logo width="140px" height="100%" fill={darkMode ? white : black} title="GISTSWAP logo" />
        </UniIcon>
      </Title>
      <HeaderLinks>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          <Trans>Swap</Trans>
        </StyledNavLink>
        <StyledNavLink
          id={`pool-nav-link`}
          to={'/pool/v2'}
          isActive={(match, { pathname }) =>
            Boolean(match) ||
            pathname.startsWith('/add') ||
            pathname.startsWith('/remove') ||
            pathname.startsWith('/increase') ||
            pathname.startsWith('/find')
          }
        >
          <Trans>Pool</Trans>
        </StyledNavLink>
        {/* <StyledNavLink id={`vote-nav-link`} to={'/vote'}>
          <Trans>Vote</Trans>
        </StyledNavLink> */}

        <StyledExternalLink id={`charts-nav-link`} href={infoLink}>
          <Trans>Charts</Trans>
          <sup>↗</sup>
        </StyledExternalLink>
      </HeaderLinks>
      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? (
                    <Dots>
                      <Trans>Claiming GIS</Trans>
                    </Dots>
                  ) : (
                    <Trans>Claim GIS</Trans>
                  )}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )}
          <AccountElement active={!!account}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0, userSelect: 'none' }} pl="0.75rem" pr="0.5rem" fontWeight={'400'}>
                <Trans>{userEthBalance?.toSignificant(3)} ETH</Trans>
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElement>
          <Menu />
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
