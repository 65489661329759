import React from 'react'
import styled from 'styled-components/macro'
import { Z_INDEX } from 'theme'

import SwaponlyBG from './../assets/images/swaponlybg01.png'

export const BodyWrapper = styled.main<{ margin?: string; maxWidth?: string }>`
  top: ${({ margin }) => margin ?? '110px'};
  max-width: ${({ maxWidth }) => maxWidth ?? '480px'};
  width: 100%;
  background: url(${SwaponlyBG});
  background-size: 100% 100%;
  background-position: center;
  // border-radius: 5px;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -6px 0px inset;
  z-index: ${Z_INDEX.deprecated_content};
  // z-index: -1;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 4rem;
    width: 95%;
  `};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...rest }: { children: React.ReactNode }) {
  return <BodyWrapper {...rest}>{children}</BodyWrapper>
}
